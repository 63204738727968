<template>
  <div v-if="isshowgrid">
    <va-card title="Banner Image List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
          <va-button flat small color="red" icon="fa fa-remove" @click="removeImage(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
    <va-modal
      v-model="showRemoveModal"
      title="Remove Banner Image"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteImage(removed_row_content)"
      @cancel="list()">
    </va-modal>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Organization</span>
            <va-select
              placeholder="Select Organization Name"
              v-model="org_name"
              searchable
              textBy="org_name"
              :options="orgArr"
              :error="!!orgNameErrors.length"
              :error-messages="orgNameErrors"
              noClear
            />
          <!--   <va-input
              v-if="isUpdate"
              v-model="org_name"
              :disabled="true"
            /> -->
            <span class="va-form-label va-form-required-label">Service Category</span>
            <va-select
              v-if="isCreate"
              placeholder="Select Service Category"
              v-model="category"
              textBy="type"
              :options="categoryObj"
              :error="!!categoryErrors.length"
              :error-messages="categoryErrors"
              noClear
              searchable
              required="true"
            />
            <va-input
              v-if="isUpdate"
              v-model="category"
              :disabled="true"
            />
            <div v-if="isUpdate">
              <va-item-section>
                <va-item-label>
                  Click to reupload Images
                </va-item-label>
              </va-item-section>
              <va-item-section side>
                <va-checkbox @input="Checkreupload()" v-model="reupolad" />
              </va-item-section>
            </div>
            <span v-if="isCreate || isReupload" class="va-form-label va-form-required-label">Banner Images</span>
            <div
              v-if="isCreate || isReupload"
              class="fields"
              v-for="(field,i) in image_url"
              :key="i">
              <!-- <va-input
                class="text_width"
                placeholder="Enter Content Id"
                v-model="field.key"
                required="true"
              /> -->
              <va-file-upload
                type="single"
                v-model="field.value"
                @input="getImage(field.value,i)"
              />
              <va-button
                v-if="image_url.length <= 4"
                class="content_width toCenter"
                flat color="primary"
                @click.prevent="addField(fields_length)"
                icon="entypo entypo-plus" />
              <va-button
                v-if="(image_url.length != 1)"
                class="content_width toCenter"
                flat color="danger"
                @click.prevent="removeField(i)"
                icon="entypo entypo-cancel" />
            </div>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createImage()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateImage()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'banner_images',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/servicecategory').then(response => {
      loader.hide()
      this.categoryObj = response.body
      this.imgData = response.body
      this.isshowForm = false
      this.isshowgrid = true
      this.$http.get(config.menu.host + '/org').then(resp => {
        this.orgArr = resp.body
        this.orgArr.map(function (arg, i) { arg.id = i + 1 })
        var role = Vue.$cookies.get('roles')
        this.orgArr = this.orgArr.filter(function (prop) {
          if ((role == 'ADMIN') || (role == 'CMS_MANAGER') || (role == 'SUPPORT')) {
            return (prop.org_type == 'OPERATOR')
          } else if (role == 'OPERATOR') {
            return (prop.org_id == Vue.$cookies.get('org-id'))
          }
        })
      })
      this.getAllImage()
    }, error => {
      loader.hide()
      if (error && error.body) {
        Vue.notify({ text: error.body, type: 'error' })
      }
    })
  },
  data () {
    return {
      term: null,
      reupolad: false,
      fields_length: 0,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      isReupload: false,
      showRemoveModal: false,
      category: '',
      title: '',
      imgData: [],
      categoryErrors: [],
      categoryObj: [],
      image_url: [{ key: '', value: '', name: '' }],
      org_name: '',
      orgNameErrors: [],
      orgArr: [],
      message: '',
    }
  },
  watch: {
    fields_length () {
      this.image_url.push({ key: '', value: '', name: '' })
    },
  },
  computed: {
    formReady () {
      return !this.categoryErrors.length && !this.orgNameErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'category',
        title: 'Category',
      },
      {
        name: 'org_name',
        title: 'Organization Name',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.imgData)
    },
  },
  methods: {
    addField (index) {
      this.fields_length++
    },
    removeField (index) {
      this.image_url = this.image_url.filter((_field, i) => i != index)
    },
    getAllImage () {
      this.$http.get(config.menu.host + '/banner_image').then(response => {
        let index = 0
        this.imgData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          return item
        })
        this.isshowgrid = true
      })
    },
    getImage (data, i) {
      var file_name = data[0].name.split('.').pop()
      if(file_name.toLowerCase() === 'png' || file_name.toLowerCase() === 'jpg' || file_name.toLowerCase() === 'jpeg'){
        this.image_url[i].name = data[0].name
      }else{
        this.image_url = [{ key: '', value: '', name: '' }]
        return Vue.notify({ text: 'please check the images format', type: 'error' })
      }
    },
    createImage () {
      this.categoryErrors = this.category ? [] : ['Category is required']
      this.orgNameErrors = this.org_name ? [] : ['Organization is required']
      if (!this.formReady) {
        return
      }
      var payload = new FormData()
      payload.append('category', this.category.short_code)
      payload.append('org_name', this.org_name.org_name)
      payload.append('org_id', this.org_name.org_id)
      var flag = false
      this.image_url.map(function (prop, i) {
        if (!prop.value[0]) {
          flag = true
          return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
        } else {
          prop['count'] = i + 1
          payload.append('image' + (i + 1), prop.value[0])
        }
      })
      payload.append('imgurl', JSON.stringify(this.image_url))
      if (!flag) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.post(config.menu.host + '/banner_image', payload).then(resp => {
          loader.hide()
          if (resp && resp.body) {
            Vue.notify({ text: resp.body, type: 'success' })
          }
          this.list()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
      }
    },
    updateImage () {
      var payload = new FormData()
      payload.append('category', this.category)
      var org_name = (this.org_name.org_name) ? this.org_name.org_name : this.org_name
      var org_id = (this.org_name.org_id) ? this.org_name.org_id : this.org_id
      payload.append('org_name', org_name)
      payload.append('org_id', org_id)
      var flag = false
      if (this.isReupload) {
        this.image_url.map(function (prop, i) {
          if (!prop.value[0]) {
            flag = true
            return Vue.notify({ text: 'Please fill the form correctly', type: 'error' })
          } else {
            prop['count'] = i + 1
            payload.append('image' + (i + 1), prop.value[0])
          }
        })

        payload.append('imgurl', JSON.stringify(this.image_url))
      }
      if (!flag) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.put(config.menu.host + '/banner_image/' + this.banner_image_id, payload).then(resp => {
          loader.hide()
          if (resp && resp.body) {
            Vue.notify({ text: resp.body, type: 'success' })
          }
          this.list()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
      }
    },
    Checkreupload () {
      this.image_url = [{ key: '', value: '', name: '' }]
      if (this.reupolad == false) {
        this.reupolad = false
        this.isReupload = false
      } else {
        this.reupolad = true
        this.isReupload = true
      }
    },
    removeImage (rowData) {
      this.message = 'Are you sure to delete banner image for the category ' + rowData.category + ' ?'
      this.showRemoveModal = true
      this.removed_row_content = rowData
    },
    deleteImage (rowData) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + '/banner_image/' + rowData.banner_image_id+'/'+rowData.org_id+'/'+rowData.category).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({ text: response.body, type: 'success' })
        }
        this.getAllImage()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = 'Add Banner Image'
      this.org_name = ''
      this.category = ''
      this.categoryErrors = []
      this.image_url = [{ key: '', value: '', name: '' }]
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true

    },
    edit (row) {
      this.title = 'Update Banner Image'
      this.banner_image_id = row.banner_image_id
      this.category = row.category
      this.org_name = (row.org_name) ? row.org_name : ''
      this.org_id = (row.org_id) ? row.org_id : ''
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
      this.image_url = [{ key: '', value: '', name: '' }]
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllImage()
      this.orgNameErrors = []
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style scoped>
.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.content_width {
  width: 30px;
  height: 30px;
}

.text_width {
  width: 170px;
  height: 30px;
}
</style>
